import React from "react";
import styled from "styled-components";

function FooterMain() {
    return (
        <Footer />
    );
}

const Footer = styled.div`
    height: 30px;
`
export default FooterMain;
